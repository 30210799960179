import React from "react"
import { Routes, Route, Navigate } from 'react-router-dom'
import MemberRoute from "../utils/MemberRoute"
import RedirectDashboardRoute from '../utils/RedirectDashboardRoute'

import Home from "../pages/Home"
import AboutUs from "../pages/AboutUs"
import CarListening from "../pages/CarListening"
import CarDetails from "../pages/CarDetails"
import ContactUs from "../pages/ContactUs"
import Register from "../pages/Register"
import Login from "../pages/Login"
import Profile from "../pages/Profile"
import Booking from "../pages/Booking"
import ForgotPassword from "../pages/ForgotPassword"
import ResetPassword from "../pages/ResetPassword"
import AccountResetPassword from '../pages/AccountResetPassword'
import EditMember from "../pages/EditMember"
import PrivacyPolicy from "../pages/PrivacyPolicy"
import NotFound from "../pages/NotFound"


const Routers = () => {
    return <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/aboutus" element={<AboutUs/>}/>
        <Route path="/vehicles" element={<CarListening/>}/>
        <Route path="/vehicles/:slug" element={<CarDetails/>}/>
        <Route path="/contactus" element={<ContactUs/>}/>
        <Route path="/register" element={
            <RedirectDashboardRoute>
                <Register/>
            </RedirectDashboardRoute>
        }/>
        <Route path="/login" element={
            <RedirectDashboardRoute>
                <Login/>
            </RedirectDashboardRoute>
        }/>
        <Route path="/forgot-password" element={
            <RedirectDashboardRoute>
                <ForgotPassword/>
            </RedirectDashboardRoute>
        }/>
        <Route path="/reset-password" element={
            <RedirectDashboardRoute>
                <ResetPassword/>
            </RedirectDashboardRoute>
        }/>
        <Route path="/account/booking" element={
                <MemberRoute>
                    <Booking />
                </MemberRoute>
        } />
        <Route path="/account/profile" element={
                <MemberRoute>
                    <Profile />
                </MemberRoute>
        } />
        <Route path="/account/profile/reset-password" element={
                <MemberRoute>
                    <AccountResetPassword />
                </MemberRoute>
        } />
        <Route path="/account/profile/edit-member" element={
                <MemberRoute>
                    <EditMember />
                </MemberRoute>
        } />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="*" element={<NotFound/>}/>
    </Routes>
}

export default Routers