import { API_URL } from '../config/constants/common.js'
import { memberAuthHeader, authHeader } from '../utils/authHeaders.js'
import {
    bookingDaysPending,
    bookingDaysSuccess,
    bookingDaysFailure,
    createBookingPending,
    createBookingSuccess,
    createBookingFailure,
    memberBookingsPending,
    memberBookingsSuccess,
    memberBookingsFailure
} from '../reducers/bookingSlice'

export const fetchBookingDays = (vehicle_id) => async(dispatch) => {
    dispatch(bookingDaysPending())

    return fetch(`${API_URL}bookings/${vehicle_id}` , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(bookingDaysSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(bookingDaysFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(bookingDaysFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(bookingDaysFailure(error.message))
        return Promise.reject(error)
    })

}

export const fetchMemberBookings = (member_id, page=1 ) => async(dispatch) => {
    dispatch(memberBookingsPending())

    const queryParams = new URLSearchParams({
        page: page.toString()
    });
    const url = `${API_URL}member-bookings/${member_id}?${queryParams.toString()}`

    return fetch(url , {
        method: 'GET',
        headers: memberAuthHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(memberBookingsSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(memberBookingsFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(memberBookingsFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(memberBookingsFailure(error.message))
        return Promise.reject(error)
    })

}

export const createBooking = ( booking ) => async(dispatch) => {
    dispatch(createBookingPending())
    console.log('booking', booking)
    const formData = new FormData()
    formData.append('vehicle_id', booking.vehicleId)
    formData.append('member_id', booking.memberId)
    formData.append('start_date', booking.startDate)
    formData.append('end_date', booking.endDate)
    formData.append('collection_time', booking.collectionTime)
    formData.append('return_time', booking.returnTime)
    formData.append('booking_status', booking.bookingStatus)
    formData.append('booking_term', booking.bookingTerm)
    formData.append('create_invoice', (booking.createInvoice == true) ? 1 : 0)

    return fetch(`${API_URL}bookings/store` , {
        method: 'POST',
        headers: memberAuthHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(createBookingSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(createBookingFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(createBookingFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(createBookingFailure(error.message))
        return Promise.reject(error)
    })

}