import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Alert } from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../actions/authAction.js'
import { Link } from 'react-router-dom';

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loading = useSelector(state => state.auth.loading)

    const [token, setToken] = useState('')
    const [email, setEmail] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState({})
    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const email = params.get('email');
        setToken(token);
        setEmail(email);
    }, [location]);

    const validate = (data) => {
        let errors = {}
        if (!data.email) errors.email = 'Email is required'
        if (!data.newPassword) errors.newPassword = 'Password is required'
        if (!data.confirmPassword) errors.confirmPassword = 'Confirm Password is required'
        return errors
    }

    const handleResetPassword = async (e) => {
        e.preventDefault()
        try {
            let formData = { 
                token,
                email,
                newPassword,
                confirmPassword
             }
            const validationErrors = validate(formData)
            setErrors(validationErrors)
            if (Object.keys(validationErrors).length === 0) {
                let result = await dispatch(resetPassword(formData))
                navigate('/login')
            }
        } catch (error) {
            if (error.code == 400) {
                let errors = {};
                for (let key in error.messages) {
                    if (error.messages.hasOwnProperty(key)) {
                        error.messages[key].forEach(msg => {
                            if (key == 'token') errors.token = msg
                            if (key == 'email') errors.email = msg
                            if (key == 'new_password') errors.newPassword = msg
                            if (key == 'confirm_password') errors.confirmPassword = msg
                            setErrors(errors)
                        })
                    }
                }
                toast.error('Validation error.')
            } else {
                toast.error('Server error.')
            }
        }
    }

    return <section>
        <Container>
            <Row className='justify-content-center'>
           
                <Col lg="5" md="5" sm="12" className='mt-2 mb-2'>
                    {errors.token && <Alert color="danger"> {errors.token}</Alert>}
                    {errors.email && <Alert color="danger"> {errors.email}</Alert>}
                    <Card className='mt-5 mb-5'>
                        <CardHeader>
                            Reset Password
                        </CardHeader>
                        <CardBody>
                            <Form autoComplete="off">
                                <FormGroup>
                                    <Label for="newPassword">Password</Label>
                                    <Input 
                                        type="password" 
                                        id="newPassword" 
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={ e => setNewPassword(e.target.value)}
                                    />
                                    {errors.newPassword && <p className='validation__error-message'>{errors.newPassword}</p>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="confirmPassword">Confirm Password</Label>
                                    <Input 
                                        type="password" 
                                        id="confirmPassword" 
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={ e => setConfirmPassword(e.target.value)}
                                    />
                                    {errors.confirmPassword && <p className='validation__error-message'>{errors.confirmPassword}</p>}
                                </FormGroup>
                                
                                <FormGroup className='d-flex align-items-center gap-3'>
                                    <button className='btn btn-success' onClick={handleResetPassword} disabled={loading}>
                                    {loading ? 'Reset Password...' : 'Reset Password'}
                                    </button>
                                    <Link to='/' className='btn' style={{ background: '#a9a1a1'}}>Go To Home</Link>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default ResetPassword