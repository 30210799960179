// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background: #e5e5e5;
    padding-top: 50px;
    padding-bottom: 15px;
}
.footer__logo i {
    color: #000;
    font-weight: 500;
}
.footer__logo span {
    color: #000;
    font-weight: 500;
}
.footer__logo-content {
    color: #000;
    margin-top: 15px;
    line-height: 30px;
}
.quick__link {
    border: none!important;
    background: transparent!important;
}
.quick__link a, .quick__link span {
    color: #000;
    text-decoration: none;
    font-weight: 300;
    transition: 0.3s;
}
.quick__link a:hover {
    color: #FF7F50;
}
.footer__link-title {
    color: #000;
    font-size: 1.8rem;
}
.footer__link-sub-title {
    color: #000;
    font-size: 1.2rem;
}
.office__info {
    color: #000;
    font-weight: 300;
}

.footer__bottom {
    border-top: 1px solid #ddd;
    margin-top: 10px;
}
@media only screen and (max-width: 768px) {
    .footer__logo-content {
        font-size: 0.8rem;
    }
    .quick__link, .office__info {
        font-size: 0.8rem;
        line-height: 1.5rem;
    }
    .footer__link-title {
        font-size: 1rem;
        margin-top: 15px;
    }
    .footer__bottom p {
        font-size: 0.7rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,sBAAsB;IACtB,iCAAiC;AACrC;AACA;IACI,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,gBAAgB;AACpB;AACA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,iBAAiB;QACjB,mBAAmB;IACvB;IACA;QACI,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".footer {\n    background: #e5e5e5;\n    padding-top: 50px;\n    padding-bottom: 15px;\n}\n.footer__logo i {\n    color: #000;\n    font-weight: 500;\n}\n.footer__logo span {\n    color: #000;\n    font-weight: 500;\n}\n.footer__logo-content {\n    color: #000;\n    margin-top: 15px;\n    line-height: 30px;\n}\n.quick__link {\n    border: none!important;\n    background: transparent!important;\n}\n.quick__link a, .quick__link span {\n    color: #000;\n    text-decoration: none;\n    font-weight: 300;\n    transition: 0.3s;\n}\n.quick__link a:hover {\n    color: #FF7F50;\n}\n.footer__link-title {\n    color: #000;\n    font-size: 1.8rem;\n}\n.footer__link-sub-title {\n    color: #000;\n    font-size: 1.2rem;\n}\n.office__info {\n    color: #000;\n    font-weight: 300;\n}\n\n.footer__bottom {\n    border-top: 1px solid #ddd;\n    margin-top: 10px;\n}\n@media only screen and (max-width: 768px) {\n    .footer__logo-content {\n        font-size: 0.8rem;\n    }\n    .quick__link, .office__info {\n        font-size: 0.8rem;\n        line-height: 1.5rem;\n    }\n    .footer__link-title {\n        font-size: 1rem;\n        margin-top: 15px;\n    }\n    .footer__bottom p {\n        font-size: 0.7rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
