import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, CardHeader, Spinner } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import CarItem from '../components/UI/CarItem'
import VehiclePagination from '../components/UI/VehiclePagination'
import TypeDropdown from '../components/UI/TypeDropDown'
import SortpriceDropdown from '../components/UI/SortpriceDropdown'
import SortorderDropdown from '../components/UI/SortorderDropdown'
// import VehicleNameSearch from '../components/UI/VehicleNameSearch'

import sortPrices from '../config/constants/sortPrices'
import vehicleTypes from '../config/constants/vehicleTypes'

import { getVehicleList, fetchVehicleNames } from '../actions/vehicleAction.js'

const CarListening = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { data: vehicles, loading, currentPage, totalPages } = useSelector(state => state.vehicle)
    // const vehicleNames = useSelector(state => state.vehicle.vehicleNames)

    const query = new URLSearchParams(useLocation().search)
    const [selectedVehicleName, setSelectedVehicleName] = useState(query.get('vehicle_name') || '')
    const [selectedType, setSelectedType] = useState(query.get('type') || '')
    const [selectedSortPrice, setSelectedSortPrice] = useState(query.get('sort_price') || '')
    const [selectedSort, setSelectedSort] = useState(query.get('sort') || '')

    useEffect(() => {
        dispatch(getVehicleList({
            page: 1,
            'name': selectedVehicleName,
            'type': selectedType,
            'sort_price': selectedSortPrice,
            'sort': selectedSort
        }))
        dispatch(fetchVehicleNames())
    }, [dispatch])

    const handleVehicleSelect = (vehicleName) => setSelectedVehicleName(vehicleName.target.value)
    const handleTypeChange = (type) => setSelectedType(type)
    const handleSortPriceChange = (price) => setSelectedSortPrice(price)
    const handleSortChange = (sort) => setSelectedSort(sort)
    const handlePageChange = (page) => dispatch(getVehicleList({ page }))

    const handleSearch = (e) => {
        e.preventDefault();
        dispatch(getVehicleList({
            page: 1,
            'name': selectedVehicleName,
            'type': selectedType,
            'sort_price': selectedSortPrice,
            'sort': selectedSort
        }))
        navigate('/vehicles')
    }

    const handleClearSearch = (e) => {
        e.preventDefault();
        setSelectedVehicleName('');
        setSelectedType('');
        setSelectedSortPrice('');
        setSelectedSort('');
    };

    useEffect(() => {
        if (selectedVehicleName === '' && selectedType === '' && selectedSortPrice === '' && selectedSort === '') {
            dispatch(getVehicleList({
                page: 1,
                'name': selectedVehicleName,
                'type': selectedType,
                'sort_price': selectedSortPrice,
                'sort': selectedSort
            }));
            navigate('/vehicles');
        }
    }, [selectedVehicleName, selectedType, selectedSortPrice, selectedSort, dispatch, navigate]);

    return <Helmet title='Cars'>
        <CommonSection title="Cars Listing" />
        <section>
            <Container>
                <Row>
                    <Col lg='3'>
                        <Form onSubmit={handleSearch}>
                            <Card>
                                <CardHeader style={{
                                    height: '50px',
                                    lineHeight: '35px',
                                    color: '#03A5D5',
                                    fontWeight: '800',
                                    fontSize: '1.3rem'
                                }}
                                >
                                Search Vehicles
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className='form__group w-100'>
                                        <label htmlFor="company">Vehicle Model/Brand</label>
                                        <input
                                            type="text"
                                            placeholder="Search By Model/Brand"
                                            name="vehicle_name"
                                            value={selectedVehicleName}
                                            onChange={handleVehicleSelect}
                                        />
                                    </FormGroup>
                                    <FormGroup className='form__group w-100'>
                                        <label htmlFor="type">Type</label>
                                        <TypeDropdown
                                            types={Object.entries(vehicleTypes)}
                                            onTypeChange={handleTypeChange}
                                            selectedValue={selectedType}
                                        />
                                    </FormGroup>
                                    <FormGroup className='form__group w-100'>
                                        <label htmlFor="sort_price">Sort By Price</label>
                                        <SortpriceDropdown
                                            Sortprices={sortPrices}
                                            onSortPriceChange={handleSortPriceChange}
                                            selectedValue={selectedSortPrice}
                                        />
                                    </FormGroup>
                                    <FormGroup className='form__group w-100'>
                                        <label htmlFor="sort_price">Sort</label>
                                        <SortorderDropdown
                                            onSortOrderChange={handleSortChange}
                                            selectedValue={selectedSort}
                                        />
                                    </FormGroup>
                                    <FormGroup className='form__group w-100'>
                                        <button type='submit' className='btn find__car-btn'>Find Car</button>
                                        <button
                                            type='button'
                                            className='btn'
                                            onClick={handleClearSearch}
                                            style={{
                                                'display': 'block',
                                                'width': '100%',
                                                'marginTop': '10px',
                                                'background': '#a9a1a1'
                                            }}
                                        >Clear Search</button>
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                    <Col lg='9'>
                        {loading ? (
                            <div className="text-center">
                                <Spinner color="primary" />
                            </div>
                        ) : (
                            <>
                                <Row>
                                    {vehicles && vehicles.map((item) => (
                                        <CarItem item={item} key={item.id} />
                                    ))}
                                </Row>
                                <Row>
                                    <Col lg="12" md="12" sm="12" className='d-flex justify-content-center'>
                                        { vehicles.length > 0 
                                        ? <VehiclePagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                        : <h4 className='text-center'> There are no cars </h4> }
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    </Helmet>
}

export default CarListening