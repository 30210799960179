import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, InputGroup, InputGroupText, Input} from 'reactstrap'
import SideNavMenu from '../components/SideNavMenu/SideNavMenu'
import '../styles/profile.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { updateMember } from '../actions/authAction'
import { useNavigate } from 'react-router-dom';

const EditMember = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authMember = useSelector(state => state.auth.authMember)
    const loading = useSelector(state => state.auth.loading)

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [userName, setUserName] = useState('')
    const [contact, setContact] = useState('')
    const [isBadDebt, setIsBadDebt] = useState(false)
    const [nric, setNric] = useState('')
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if(authMember.id) {
            setId(authMember.id)
            setName(authMember.name)
            setEmail(authMember.email)
            setUserName(authMember.username)
            setIsBadDebt(authMember.is_bad_debt)
            setContact(authMember.contact)
            setNric(authMember.nric)
        }
    }, [authMember])

    const validate = (data) => {
        let errors = {}
        if (!data.name) errors.name = 'Name is required'
        if (!data.email) errors.email = 'Email is required'
        if (!data.userName) errors.userName = 'Username is required'
        if (!data.contact) errors.contact = 'Contact is required'
        if (!data.nric) errors.nric = 'NRIC is required'
        return errors
    }

    const handleEditMember = async(e) => {
        e.preventDefault()
        try {
            let formData = {
                id,
                name,
                email,
                userName,
                contact,
                isBadDebt,
                nric,
            }
            const validationErrors = validate(formData)
            setErrors(validationErrors)
            if (Object.keys(validationErrors).length === 0) {
                const result = await dispatch(updateMember(formData))
                navigate('/account/profile')
            }
        } catch (error) {
            if (error.code == 400) {
                let errors = {};
                for (let key in error.messages) {
                    if (error.messages.hasOwnProperty(key)) {
                        error.messages[key].forEach(msg => {
                            if (key == 'name') errors.name = msg
                            if (key == 'email') errors.email = msg
                            if (key == 'username') errors.userName = msg
                            if (key == 'is_bad_debt') errors.contact = msg
                            if (key == 'contact') errors.nric = msg
                            if (key == 'nric') errors.password = msg
                            setErrors(errors)
                        })
                    }
                }
                toast.error('Validation error.')
            } else {
                toast.error('Server error.')
            }
        }
    }

    return <section style={{ background: '#ddd'}}>
        <Container>
            <Row>
                <Col lg="3" md="3" sm="12">
                    <SideNavMenu/>
                </Col>
                <Col lg="9" md="9" sm="12" className="profile">
                    <h3 className='mb-4'>Edit Member</h3>
                    <Card className='pt-4 pr-4 pl-4 pb-2'>
                        <CardBody>
                        <Form autoComplete='off'>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="name">Name</Label>
                                            <Input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            {errors.name && <p className='validation__error-message'>{errors.name}</p>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {errors.email && <p className='validation__error-message'>{errors.email}</p>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="userName">User Name</Label>
                                            <Input
                                                type="text"
                                                id="userName"
                                                name="userName"
                                                value={userName}
                                                onChange={(e) => setUserName(e.target.value)}
                                            />
                                            {errors.username && <p className='validation__error-message'>{errors.username}</p>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="nric">NRIC</Label>
                                            <Input
                                                type="text"
                                                id="nric"
                                                name="nric"
                                                value={nric}
                                                onChange={(e) => setNric(e.target.value)}
                                            />
                                            {errors.nric && <p className='validation__error-message'>{errors.nric}</p>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="contact">Contact</Label>
                                            <Input
                                                type="text"
                                                id="contact"
                                                name="contact"
                                                value={contact}
                                                onChange={(e) => setContact(e.target.value)}
                                            />
                                            {errors.contact && <p className='validation__error-message'>{errors.contact}</p>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" md="6" sm="12" style={{ alignSelf: 'center' }}>
                                        <FormGroup check className='mb-3'>
                                            <Label check>
                                                <Input
                                                    type="checkbox"
                                                    name="isBadDebt"
                                                    checked={isBadDebt}
                                                    onChange={e => setIsBadDebt(!isBadDebt)}
                                                />
                                                Is Bad Debt
                                            </Label>
                                            {errors.isBadDebt && <p className='validation__error-message'>{errors.isBadDebt}</p>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup className='d-flex align-items-center gap-3'>
                                    <button className='btn btn-success' onClick={handleEditMember} disabled={loading}>
                                    {loading ? 'Updating...' : 'Update'}
                                    </button>
                                    <Link to="/account/profile" className='btn btn-info' style={{ background: '#a9a1a1' }}>Go Back Profile</Link>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default EditMember