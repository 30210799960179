export function authHeader() {
    return {
        'Accept': 'application/json'
    }
}

export function memberAuthHeader() {
    let memberObj = JSON.parse(localStorage.getItem('member'))
    if (memberObj && memberObj.token) {
        return {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + memberObj.token
        }
    } else {
        return {
            'Accept': 'application/json'
        }
    }
}
