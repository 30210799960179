// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side__menu > li > a {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 5px;
}
.side__menu > li > a > i {
    font-size: 1.8rem;
}
.side__menu > li > a > label {
    padding-top: 10px;
    cursor: pointer;
    transition: 0.3s;
}
.side__menu > li:hover, .side__nav-active {
   color: #03A5D5!important;
}`, "",{"version":3,"sources":["webpack://./src/styles/side-nav-menu.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB;AACA;GACG,wBAAwB;AAC3B","sourcesContent":[".side__menu > li > a {\n    font-size: 1.2rem;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n.side__menu > li > a > i {\n    font-size: 1.8rem;\n}\n.side__menu > li > a > label {\n    padding-top: 10px;\n    cursor: pointer;\n    transition: 0.3s;\n}\n.side__menu > li:hover, .side__nav-active {\n   color: #03A5D5!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
