import React from "react"
import { Card, CardHeader, CardBody, Row, Col, Label, Input } from "reactstrap"
import vehicleTypes from "../../config/constants/vehicleTypes"

const CarSpecification = ({ vehicle }) => {
    return <>
        <Row>
            <Col lg="12" style={{ marginBottom: '10px'}}> 
                <Label style={{ marginBottom: '0px'}}>Seats</Label>
                <div>
                    <i className="ri-armchair-line" style={{ marginRight: '10px' }}></i>
                    <Label className='specification__label'>{vehicle.seats}</Label>
                </div>
            </Col>
            {/* <Col lg="12" style={{ marginBottom: '10px'}}> 
                <Label style={{ marginBottom: '0px'}}>License Plate</Label>
                <div>
                    <i className="ri-id-card-line" style={{ marginRight: '10px' }}></i>
                    <Label className='specification__label'>{vehicle.license_plate}</Label>
                </div>
            </Col> */}
            <Col lg="12" style={{ marginBottom: '10px'}}> 
                <Label style={{ marginBottom: '0px'}}>Type</Label>
                <div>
                    <i className="ri-tools-line" style={{ marginRight: '10px' }}></i>
                    <Label className='specification__label'>{vehicleTypes[vehicle.type]}</Label>
                </div>
            </Col>
            <Col lg="12" style={{ marginBottom: '10px'}}> 
                <Label style={{ marginBottom: '0px'}}>Price</Label>
                <div>
                    <i className="ri-price-tag-3-line" style={{ marginRight: '10px' }}></i>
                    <Label className='specification__label'><b> S${vehicle.price} / 1 Day </b></Label>
                </div>
            </Col>
        </Row>
    </>
    // return <Card>
    //     <CardHeader> Specifications </CardHeader>
    //     <CardBody>
    //         <Row className='specification__row'>
    //             <Col lg="6" md="6" sm="6">
    //                 <div className='specification__section'>
    //                     <Label className='specification__label'>Company Name</Label>
    //                     <div>
    //                         <i className="ri-home-6-line" style={{ marginRight: '10px' }}></i>
    //                         <Label className='specification__label'>{vehicle.vendor_name}</Label>
    //                     </div>

    //                 </div>
    //             </Col>
    //             <Col lg="6" md="6" sm="6">
    //                 <div>
    //                     <Label>Car Name</Label>
    //                     <div>
    //                         <i className="ri-car-line" style={{ marginRight: '10px' }}></i>
    //                         <Label className='specification__label'>{vehicle.name}</Label>
    //                     </div>
    //                 </div>
    //             </Col>
    //         </Row>
    //         <Row className='specification__row'>
    //             <Col lg="6" md="6" sm="6">
    //                 <div className='specification__section'>
    //                     <Label className='specification__label'>Seats</Label>
    //                     <div>
    //                         <i className="ri-armchair-line" style={{ marginRight: '10px' }}></i>
    //                         <Label className='specification__label'>{vehicle.seats}</Label>
    //                     </div>

    //                 </div>
    //             </Col>
    //             <Col lg="6" md="6" sm="6">
    //                 <div>
    //                     <Label>License Plate</Label>
    //                     <div>
    //                         <i className="ri-id-card-line" style={{ marginRight: '10px' }}></i>
    //                         <Label className='specification__label'>{vehicle.license_plate}</Label>
    //                     </div>
    //                 </div>
    //             </Col>
    //         </Row>
    //         <Row className='specification__row'>
    //             <Col lg="6" md="6" sm="6">
    //                 <div className='specification__section'>
    //                     <Label className='specification__label'>Type</Label>
    //                     <div>
    //                         <i className="ri-tools-line" style={{ marginRight: '10px' }}></i>
    //                         <Label className='specification__label'>{vehicleTypes[vehicle.type]}</Label>
    //                     </div>

    //                 </div>
    //             </Col>
    //             <Col lg="6" md="6" sm="6">
    //                 <div>
    //                     <Label>Price</Label>
    //                     <div>
    //                         <i className="ri-price-tag-3-line" style={{ marginRight: '10px' }}></i>
    //                         <Label className='specification__label'><b> S${vehicle.price} / 1 Day </b></Label>
    //                     </div>
    //                 </div>
    //             </Col>
    //         </Row>
    //     </CardBody>
    // </Card>
}

export default CarSpecification