import React, { useState, useEffect } from 'react'
import { Form, FormGroup } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import VehicleNameSearch from './VehicleNameSearch'
import TypeDropdown from './TypeDropDown'
import SortpriceDropdown from './SortpriceDropdown'

import vehicleTypes from '../../config/constants/vehicleTypes'
import sortPrices from '../../config/constants/sortPrices'
import { getVendorList } from '../../actions/vendorAction'
import { fetchVehicleNames } from '../../actions/vehicleAction'

import '../../styles/find-car-form.css'

const FindCardForm = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const vehicles = useSelector(state => state.vehicle.vehicleNames)

    // const [selectedVehicleName, setSelectedVehicleName] = useState('')
    const [searchVehicleModelBrand, setVehicleModelBrand] = useState('')
    const [selectedType, setSelectedType] = useState('')
    const [selectedSortPrice, setSelectedSortPrice] = useState('')

    // const handleVehicleSelect = (vehicleName) => setSelectedVehicleName(vehicleName)
    const handleSearchModel = (data) => setVehicleModelBrand(data.target.value)
    const handleTypeChange = (type) => setSelectedType(type)
    const handleSortPriceChange = (price) => setSelectedSortPrice(price)

    const handleSearch = (e) => {
        e.preventDefault();
        const query = new URLSearchParams({
            'vehicle_name': searchVehicleModelBrand,
            'type': selectedType,
            'sort_price': selectedSortPrice,
            'sort': 'desc'
        }).toString();
        navigate(`/vehicles?${query}`);
    }


    useEffect(() => {
        dispatch(getVendorList())
        dispatch(fetchVehicleNames())
    }, [dispatch])

    return <Form className='form'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
            <FormGroup className='form__group'>
                <label htmlFor="company">Vehicle Model/Brand</label>
                <input
                    type="text"
                    placeholder="Search By Model/Brand"
                    name="vehicle_name"
                    value={searchVehicleModelBrand}
                    onChange={handleSearchModel}
                />
            </FormGroup>
            <FormGroup className='form__group'>
                <label htmlFor="type">Type</label>
                <TypeDropdown
                    types={Object.entries(vehicleTypes)}
                    onTypeChange={handleTypeChange}
                    selectedValue={selectedType}
                />
            </FormGroup>
            <FormGroup className='form__group'>
                <label htmlFor="sort_price">Sort By Price</label>
                <SortpriceDropdown
                    Sortprices={sortPrices}
                    onSortPriceChange={handleSortPriceChange}
                    selectedValue={selectedSortPrice}
                />
            </FormGroup>
            <FormGroup className='form__group'>
                <button className='btn find__car-btn' onClick={handleSearch}>Find Car</button>
            </FormGroup>
        </div>
    </Form>
}

export default FindCardForm