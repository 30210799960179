import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap'
import { signup } from '../actions/authAction.js'
import RequiredField from '../components/UI/RequiredField.jsx'

const Register = () => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.auth.loading)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [contact, setContact] = useState('')
    const [isBadDebt, setIsBadDebt] = useState(false)
    const [nric, setNric] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errors, setErrors] = useState({})

    const validate = (data) => {
        let errors = {}
        if (!data.name) errors.name = 'Name is required'
        if (!data.email) errors.email = 'Email is required'
        if (!data.contact) errors.contact = 'Contact is required'
        if (!data.nric) errors.nric = 'NRIC is required'
        if (!data.password) errors.password = 'Password is required'
        if (data.password.length < 6) errors.password = 'Password is too short'
        if (data.password !== data.confirmPassword) errors.confirmPassword = 'Passwords must match'
        return errors
    }

    const handleRegister = async (e) => {
        e.preventDefault()
        setErrors({})
        try {
            let formData = {
                name,
                email,
                name,
                contact,
                isBadDebt,
                nric,
                password,
                confirmPassword
            }
            const validationErrors = validate(formData)
            setErrors(validationErrors)
            if (Object.keys(validationErrors).length === 0) {
                const result = await dispatch(signup(formData))
                toast.success('Register Successfully!')
                setName('')
                setEmail('')
                setContact('')
                setIsBadDebt(false)
                setNric('')
                setPassword('')
                setConfirmPassword('')
            }
        } catch (error) {
            if (error.code == 400) {
                let errors = {};
                for (let key in error.messages) {
                    if (error.messages.hasOwnProperty(key)) {
                        error.messages[key].forEach(msg => {
                            if (key == 'name') errors.name = msg
                            if (key == 'email') errors.email = msg
                            if (key == 'is_bad_debt') errors.contact = msg
                            if (key == 'contact') errors.nric = msg
                            if (key == 'nric') errors.password = msg
                            if (key == 'password') errors.password = msg
                            if (key == 'password_confirmation') errors.confirmPassword = msg
                            setErrors(errors)
                        })
                    }
                }
                toast.error('Validation error.')
            } else {
                toast.error('Server error.')
            }
        }

    };

    return <section>
        <Container>
            <Row className='justify-content-center'>
                <Col lg="10" md="10" sm="12">
                    <Card>
                        <CardHeader>
                            <b>Register</b>
                        </CardHeader>
                        <CardBody>
                            <Form autoComplete='off'>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="name">Name <RequiredField/> </Label>
                                            <Input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                            {errors.name && <p className='validation__error-message'>{errors.name}</p>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="email">Email <RequiredField/> </Label>
                                            <Input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {errors.email && <p className='validation__error-message'>{errors.email}</p>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="contact">Contact <RequiredField/> </Label>
                                            <Input
                                                type="text"
                                                id="contact"
                                                name="contact"
                                                value={contact}
                                                onChange={(e) => setContact(e.target.value)}
                                            />
                                            {errors.contact && <p className='validation__error-message'>{errors.contact}</p>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="nric">NRIC <RequiredField/> </Label>
                                            <Input
                                                type="text"
                                                id="nric"
                                                name="nric"
                                                value={nric}
                                                onChange={(e) => setNric(e.target.value)}
                                            />
                                            {errors.nric && <p className='validation__error-message'>{errors.nric}</p>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="password">Password <RequiredField/> </Label>
                                            <Input
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            {errors.password && <p className='validation__error-message'>{errors.password}</p>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6" md="6" sm="12">
                                        <FormGroup>
                                            <Label for="confirmPassword">Confirm Password <RequiredField/> </Label>
                                            <Input
                                                type="password"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            {errors.confirmPassword && <p className='validation__error-message'>{errors.confirmPassword}</p>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup className='d-flex align-items-center gap-3'>
                                    <button className='btn btn-success' onClick={handleRegister} disabled={loading}>
                                    {loading ? 'Registering...' : 'Register'}
                                    </button>
                                    <Link to="/" className='btn btn-info' style={{ background: '#a9a1a1' }}>Go Back Home</Link>
                                </FormGroup>
                                <FormGroup>
                                    Already a Member? <Link to="/login" style={{ color: '#03A5D5', fontWeight: '700' }}> Login here </Link>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default Register