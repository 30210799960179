import { createSlice } from '@reduxjs/toolkit'

const vendorSlice = createSlice({
    name: 'vendor',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {
        vendorListPending: (state) => {
            state.loading = true
            state.error = null
        },
        vendorListSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        vendorListFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    vendorListPending, 
    vendorListSuccess, 
    vendorListFailure, 
} = vendorSlice.actions

export default vendorSlice.reducer