import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RedirectDashboardRoute = ({ children }) => {
    const [memberObj, setMemberObj] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const memberData = JSON.parse(localStorage.getItem('member'));
        setMemberObj(memberData);
        if (memberData && memberData.token) {
            navigate('/account/profile', { state: { from: location } });
        }
    }, [navigate, location]);

    return children
};

export default RedirectDashboardRoute;

