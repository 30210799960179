import React, { useEffect } from 'react'
import { Container, Row, Col, CardHeader, CardBody, Card, Label, Form, FormGroup, Input, Button } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import { useParams } from "react-router-dom"
import BookingForm from '../components/UI/BookingForm'
import { useDispatch, useSelector } from 'react-redux'
import { fetchVehicle } from '../actions/vehicleAction.js'
import { SERVER_URL } from '../config/constants/common.js'
import '../styles/car-details.css'
import CarSpecification from '../components/UI/CarSpecification'

const CarDetails = () => {
    const dispatch = useDispatch()
    const {slug} = useParams()
    const { details: vehicle } = useSelector(state => state.vehicle)
    const imgUrl = `${SERVER_URL}${vehicle.image}`

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    useEffect(() => {
        dispatch(fetchVehicle(slug))
    }, [dispatch])

    return <Helmet title={vehicle.name}>
        <section>
            <Container className='car__details'>
                <Row>
                    <Col lg='7'>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <h3 style={{ textAlign: 'center', marginTop: '20px', marginBottom: '40px'}}>{ vehicle.name }</h3>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '15px' }}>
                                    <Col lg="8"> <img src={imgUrl} alt="" className='w-100'/> </Col>
                                    <Col lg="4">
                                        <CarSpecification vehicle={vehicle}/>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg='5'>
                        <BookingForm vehicle={vehicle}/>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12" md="12" sm="12">
                        
                    </Col>
                </Row>
            </Container>
        </section>
    </Helmet>
}

export default CarDetails