import { createSlice } from '@reduxjs/toolkit'

const holidaySlice = createSlice({
    name: 'holiday',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {
        holidayListPending: (state) => {
            state.loading = true
            state.error = null
        },
        holidayListSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        holidayListFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    holidayListPending, 
    holidayListSuccess, 
    holidayListFailure, 
} = holidaySlice.actions

export default holidaySlice.reducer