import { API_URL } from '../config/constants/common.js'
import {
    companyDataPending,
    companyDataSuccess,
    companyDataFailure,
} from '../reducers/companySlice'
import { authHeader } from '../utils/authHeaders.js'

export const fetchCompanyData = () => async(dispatch) => {
    dispatch(companyDataPending())

    return fetch(`${API_URL}company/getinfo` , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(companyDataSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(companyDataFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(companyDataFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(companyDataFailure(error.message))
        return Promise.reject(error)
    })

}