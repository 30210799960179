import { API_URL } from '../config/constants/common.js'
import {
    vehicleListPending,
    vehicleListSuccess,
    vehicleListFailure,
    vehiclePending,
    vehicleSuccess,
    vehicleFailure,
    unavailableVehicleDaysPending,
    unavailableVehicleDaysSuccess,
    unavailableVehicleDaysFailure,
    vehicleNamesPending,
    vehicleNamesSuccess,
    vehicleNamesFailure
} from '../reducers/vehicleSlice'
import { authHeader } from '../utils/authHeaders.js'

export const getVehicleList = ( params = {}) => async( dispatch ) => {
    dispatch(vehicleListPending())

    const queryString = new URLSearchParams(params).toString();
    const url = `${API_URL}vehicles${queryString ? `?${queryString}` : ''}`;

    return fetch(url , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(vehicleListSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(vehicleListFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(vehicleListFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(vehicleListFailure(error.message))
        return Promise.reject(error)
    })
}

export const fetchVehicle = (id) => async(dispatch) => {
    dispatch(vehiclePending())

    return fetch(`${API_URL}vehicles/${id}` , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(vehicleSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(vehicleFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(vehicleFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(vehicleFailure(error.message))
        return Promise.reject(error)
    })

}

export const fetchUnavailableVehicleDays = (vehicle_id) => async(dispatch) => {
    dispatch(unavailableVehicleDaysPending())

    return fetch(`${API_URL}unavailable-days/${vehicle_id}` , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(unavailableVehicleDaysSuccess(result))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(unavailableVehicleDaysFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(unavailableVehicleDaysFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(unavailableVehicleDaysFailure(error.message))
        return Promise.reject(error)
    })

}

export const fetchVehicleNames = (params ={}) => async(dispatch) => {
    dispatch(vehicleNamesPending())

    const queryString = new URLSearchParams(params).toString();
    const url = `${API_URL}get-vehiclenames${queryString ? `?${queryString}` : ''}`;

    return fetch(url , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(vehicleNamesSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(vehicleNamesFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(vehicleNamesFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(vehicleNamesFailure(error.message))
        return Promise.reject(error)
    })
}