// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about__section-item {
    column-gap: 3rem;
}
.about__section-item p i {
    color: #FF7F50;
    font-size: 1.1rem;
}
@media only screen and (max-width:992px) {
    .about__section-content h2{
        font-size: 1.5rem;
    }
    .about__section-item {
        column-gap: 2.7rem;
    }
    .about__section-item p {
        font-size: 0.6rem;
        margin-bottom: 0;
    }
    .section__description i {
        font-size: 0.8rem!important;
    }
}
@media only screen and (max-width:768px) {
    .about__img {
        margin-top: 30px;
    }
}
@media only screen and (max-width:576px) {
    .about__section {
        margin-top: 280px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/about-section.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,iBAAiB;QACjB,gBAAgB;IACpB;IACA;QACI,2BAA2B;IAC/B;AACJ;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".about__section-item {\n    column-gap: 3rem;\n}\n.about__section-item p i {\n    color: #FF7F50;\n    font-size: 1.1rem;\n}\n@media only screen and (max-width:992px) {\n    .about__section-content h2{\n        font-size: 1.5rem;\n    }\n    .about__section-item {\n        column-gap: 2.7rem;\n    }\n    .about__section-item p {\n        font-size: 0.6rem;\n        margin-bottom: 0;\n    }\n    .section__description i {\n        font-size: 0.8rem!important;\n    }\n}\n@media only screen and (max-width:768px) {\n    .about__img {\n        margin-top: 30px;\n    }\n}\n@media only screen and (max-width:576px) {\n    .about__section {\n        margin-top: 280px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
