import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, InputGroup, InputGroupText, Input} from 'reactstrap'
import SideNavMenu from '../components/SideNavMenu/SideNavMenu'
import '../styles/profile.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { clearResetPasswordNotification, clearUpdateMemberNotification } from '../reducers/authSlice'
import { removeMember } from '../actions/authAction'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authMember = useSelector(state => state.auth.authMember)
    const resetPasswordNotification = useSelector((state) => state.auth.resetPasswordNotification)
    const updateMemberNotification  = useSelector(state => state.auth.updateMemberNotification)

    useEffect(() => {
        if (resetPasswordNotification) {
            toast.success('Password Changed Successfully!')
            dispatch(clearResetPasswordNotification());
        }
    }, [resetPasswordNotification, dispatch]);

    useEffect(() => {
        if (updateMemberNotification) {
            toast.success('Member Updated Successfully!')
            dispatch(clearUpdateMemberNotification());
        }
    }, [updateMemberNotification, dispatch]);

    const removeMemberHandler = async (e) => {
        e.preventDefault()
        
        const isConfirmed = window.confirm('Are you sure you want to remove?')
        
        if (isConfirmed) {
            try {
                const result = await dispatch(removeMember(authMember.id))
                localStorage.removeItem('member')
                navigate('/login')
            } catch (error) {
                if (error.code === 400) {
                    toast.error('Bad Request.')
                } else {
                    toast.error('Server error.')
                }
            }
        }
    }

    return <section style={{ background: '#ddd'}}>
        <Container>
            <Row>
                <Col lg="3" md="3" sm="12">
                    <SideNavMenu/>
                </Col>
                <Col lg="9" md="9" sm="12" className="profile">
                    <h3 className='mb-4'>Personal Settings</h3>
                    <Card className='pt-4 pr-4 pl-4 pb-2'>
                        <CardBody>
                           <Row>
                                <Col md="4" lg="4" sm="12">
                                    <div className='profile__picture'>
                                        <i className="ri-user-fill"></i>
                                    </div>
                                </Col>
                                <Col md="8" lg="8" sm="12">
                                    <Form>
                                        <FormGroup>
                                            <Label htmlFor='user_name'>User Name</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <i className="ri-font-family"></i>
                                                </InputGroupText>
                                                <Input id="user_name" name="user_name" readOnly value={authMember.name}></Input>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor='user_name'>User Email Address</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                    <i className="ri-mail-fill"></i>
                                                </InputGroupText>
                                                <Input id="user_name" name="user_name" readOnly value={authMember.email}></Input>
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor='user_name'>User Phone No</Label>
                                            <InputGroup>
                                                <InputGroupText>
                                                <i className="ri-phone-fill"></i>
                                                </InputGroupText>
                                                <Input id="user_name" name="user_name" readOnly value={authMember.contact}></Input>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                </Col>
                           </Row>
                           <Row>
                                <Col lg='12' md='12' sm='12' className='pt-3'>
                                    <div className='profile__footer'>
                                        <Link to="/account/profile/reset-password" className='btn'> Change Password </Link>
                                        <Link to="/account/profile/edit-member" className='btn'> Edit Profile </Link>
                                        <button className='btn' onClick={ removeMemberHandler }>Delete Account</button>
                                    </div>
                                </Col>
                           </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default Profile