// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service__item span i {
    font-size: 2.5rem;
    color: #FF7F50;
}
.service__item h6 {
    font-size: 1.2rem;
    font-weight: 600;
    transition: .3s;
}
.service__item h6:hover {
    color: #FF7F50;
}
@media only screen and (max-width: 768px) {
    .service__item h6 {
        font-size: 1.1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/services-list.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;AAClB;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".service__item span i {\n    font-size: 2.5rem;\n    color: #FF7F50;\n}\n.service__item h6 {\n    font-size: 1.2rem;\n    font-weight: 600;\n    transition: .3s;\n}\n.service__item h6:hover {\n    color: #FF7F50;\n}\n@media only screen and (max-width: 768px) {\n    .service__item h6 {\n        font-size: 1.1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
