import React, { useState} from 'react'

const TypeDropdown = ({ types, onTypeChange, selectedValue='' }) => {
    const handleChange = (event) => {
        onTypeChange(event.target.value)
    }

    return (
        <select name="type" id="type" onChange={handleChange} value={selectedValue}>
            <option value="" disabled>Select an option</option>
            {types.map(([key, type]) => (
                <option value={key} key={key}>
                    {type}
                </option>
            ))}
        </select>
    )
}

export default TypeDropdown