import authSlice from './authSlice'
import vehicleSlice from './vehicleSlice'
import vendorSlice from './vendorSlice'
import holidaySlice from './holidaySlice'
import bookingSlice from './bookingSlice'
import companySlice from './companySlice'

const combineReducers = {
    auth: authSlice,
    vehicle: vehicleSlice,
    vendor: vendorSlice,
    holiday: holidaySlice,
    booking: bookingSlice,
    company: companySlice
}

export default combineReducers