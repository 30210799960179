import React, { useEffect } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import SideNavMenu from '../components/SideNavMenu/SideNavMenu'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMemberBookings } from '../actions/bookingAction'
import BookingRow from '../components/Booking/BookingRow'
import VehiclePagination from '../components/UI/VehiclePagination'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { resetBookingMessage } from '../reducers/bookingSlice'

const Booking = () => {
    const dispatch = useDispatch()
    const authMember = useSelector(state => state.auth.authMember)
    const { memberBookings: bookings, currentPage, totalPages, createBookingMessage } = useSelector(state => state.booking)

    useEffect(() => {
        if (authMember) {
            dispatch(fetchMemberBookings(authMember.id))
        }
    }, [dispatch, authMember])

    useEffect(() => {
        if (createBookingMessage) {
            toast.success('Booking Created Successfully')
            dispatch(resetBookingMessage());
        }
    }, [createBookingMessage, dispatch]);

    const handlePageChange = (page) => dispatch(fetchMemberBookings(authMember.id, page))

    return <section style={{ background: '#ddd' }}>
        <Container>
            <Row>
                <Col lg="3" md="3" sm="12">
                    <SideNavMenu />
                </Col>
                <Col lg="9" md="9" sm="12" >
                    <h3 className='mb-4'> Booking List </h3>
                    <Card className='pt-2 pr-4 pl-4 pb-2'>
                        <CardBody>
                            {bookings && bookings.length > 0 ? (
                                <>
                                    {bookings.map((item) => (
                                        <BookingRow item={item} key={item.id} />
                                    ))}
                                    <Row>
                                        <Col lg="12" md="12" sm="12" className='d-flex justify-content-center'>
                                            <VehiclePagination
                                                currentPage={currentPage}
                                                totalPages={totalPages}
                                                onPageChange={handlePageChange}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <Row>
                                    <Col lg="12" md="12" sm="12" className='d-flex justify-content-center'>
                                        <p style={{
                                            marginBottom: '0px',
                                            fontSize: '1.1rem',
                                            fontWeight: '700'
                                        }}>No bookings available</p>
                                    </Col>
                                </Row>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default Booking