import React, { useState} from 'react'

const SortpriceDropdown = ({ Sortprices, onSortPriceChange, selectedValue='' }) => {
    const handleChange = (event) => {
        onSortPriceChange(event.target.value)
    }

    return (
        <select name="sort_price" id="sort_price" value={selectedValue} onChange={handleChange}>
            <option value="" disabled>Select an option</option>
            {Sortprices.map((Sortprice, index) => (
                    <option value={Sortprice.key} key={index}>
                        {Sortprice.label}
                    </option>
                ))}
        </select>
    )
}

export default SortpriceDropdown
