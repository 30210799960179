import { createSlice } from '@reduxjs/toolkit'
let member = JSON.parse(localStorage.getItem('member'))

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        loading: false,
        error: null,
        authMember: member ? member : null,
        registerUserInfo: null,
        resetPasswordNotification: false,
        updateMemberNotification: false,
        removeMemberNotification: false
    },
    reducers: {
        registrationPending: (state) => {
            state.loading = true
            state.error = null
        },
        registrationSuccess: (state, action) => {
            state.loading = false
            state.registerUserInfo = action.payload
        },
        registrationFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        loginPending: (state) => {
            state.loading = true
            state.error = null
        },
        loginSuccess: (state, action) => {
            state.loading = false
            state.authMember = action.payload
        },
        loginFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        logoutSuccess: (state) => {
            state.authMember = null
            localStorage.removeItem('member')
        },
        forgotPasswordPending: (state) => {
            state.loading = true
            state.error = null
        },
        forgotPasswordSuccess: (state, action) => {
            state.loading = false
            state.SentEmailForForgotPassword = action.payload
        },
        forgotPasswordFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        resetPasswordPending: (state) => {
            state.loading = true
            state.error = null
        },
        resetPasswordSuccess: (state) => {
            state.loading = false
            state.resetPasswordNotification = true
        },
        resetPasswordFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        clearResetPasswordNotification: (state ) => {
            state.resetPasswordNotification = false
        },
        updateMemberPending: (state) => {
            state.loading = true
            state.error = null
        },
        updateMemberSuccess: (state, action) => {
            state.loading = false
            state.authMember = action.payload
            state.updateMemberNotification = true
        },
        updateMemberFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        clearUpdateMemberNotification: (state) => {
            state.updateMemberNotification = false
        },
        removeMemberPending: (state) => {
            state.loading = true 
            state.error = null
        },
        removeMemberSuccess: (state) => {
            state.loading = false 
            state.removeMemberNotification= true
        },
        removeMemberFailure: (state, action) => {
            state.loading = false 
            state.error = action.payload
        },
        clearRemoveMemberNotification: (state) => {
            state.removeMemberNotification= false
        }
    }
})

export const { 
    loginPending, 
    loginSuccess, 
    loginFailure, 
    logoutSuccess,
    registrationPending,
    registrationSuccess,
    registrationFailure,
    resetPasswordPending,
    resetPasswordSuccess,
    resetPasswordFailure,
    forgotPasswordPending,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    clearResetPasswordNotification,
    updateMemberPending,
    updateMemberSuccess,
    updateMemberFailure,
    clearUpdateMemberNotification,
    removeMemberPending,
    removeMemberSuccess,
    removeMemberFailure,
    clearRemoveMemberNotification
} = authSlice.actions

export default authSlice.reducer