import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardHeader, CardBody, Form, Row, Col, Label, Button } from 'reactstrap'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchHolidayList } from '../../actions/holidayAction'
import { fetchBookingDays } from '../../actions/bookingAction'
import { fetchUnavailableVehicleDays } from '../../actions/vehicleAction'
import { createBooking } from '../../actions/bookingAction'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../../styles/booking-form.css'

const getUnavailbeDays = (unAvailableDays) => {
    return unAvailableDays.map( unAvailableDay => new Date(unAvailableDay))
}

const getHolidayDates = (holidays) => {
    return holidays.map(holiday => new Date(holiday.date));
};

const isHoliday = (date, holidays) => {
    return holidays.some(holiday => 
        date.getFullYear() === holiday.getFullYear() &&
        date.getMonth() === holiday.getMonth() &&
        date.getDate() === holiday.getDate()
    );
};

const isUnavailable = (date, unavailabledays) => {
    return unavailabledays.some(unavailableday => 
        date.getFullYear() === unavailableday.getFullYear() &&
        date.getMonth() === unavailableday.getMonth() &&
        date.getDate() === unavailableday.getDate()
    );
};

const isWeekend = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

const filterDate = (date, holidays, unavailabledays) => {
    return !isHoliday(date, holidays) && !isUnavailable(date, unavailabledays) && !isWeekend(date);
};

const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end date
    return diffDays;
};

const BookingForm = ({ vehicle = {} }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { data: holidays } = useSelector(state => state.holiday)
    const { unavailableVehicleDays: unavailabledays } = useSelector(state => state.vehicle)
    const authMember = useSelector(state => state.auth.authMember)

    const [vehicleId, setVehicleId] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [price, setPrice] = useState('' )
    const [totalPrice, setTotalPrice] = useState('' )
    const [collectionTime, setCollectionTime] = useState('3pm')
    const [returnTime, setReturnTime] = useState('3pm')
    const [createInvoice,  setCreateInvoice] = useState(false)
    const [errors, setErrors] = useState({})

    const BOOKING_STATUS_PENDING = 1
    const BOOKING_SHORT_TERM = 1

    useEffect(() => {
        dispatch(fetchHolidayList())
    }, [dispatch])

    useEffect(() => {
        if (vehicle.id) {
            dispatch(fetchBookingDays(vehicle.id))
            dispatch(fetchUnavailableVehicleDays(vehicle.id))
        }
    }, [dispatch,vehicle])

    useEffect(() => {
        if (vehicle) {
            setVehicleId(vehicle.id)
        }
    }, [vehicle])

    useEffect(() => {
        const startDate = new Date();
        const formattedStartDate = startDate.toISOString().split('T')[0]; 
        setStartDate(formattedStartDate);

        const endDate = new Date();
        endDate.setDate(startDate.getDate() + 1);
        const formattedEndDate = endDate.toISOString().split('T')[0];
        setEndDate(formattedEndDate);
    }, [])

    useEffect(() => {
        setPrice(vehicle.price || '');
        setTotalPrice(vehicle.price || '');
    }, [vehicle]);

    useEffect(() => {
        const days = calculateDaysDifference(startDate, endDate);
        setTotalPrice((days - 1) * price);
    }, [startDate, endDate])

    const handleStartDateChange = (date) => {
        const newStartDate = date.toISOString().split('T')[0];
        setStartDate(newStartDate);
    
        const minEndDate = new Date(date);
        minEndDate.setDate(minEndDate.getDate() + 1);
        const minEndDateString = minEndDate.toISOString().split('T')[0];
    
        if (endDate < minEndDateString) {
            setEndDate(minEndDateString);
        }
    }

    const handleEndDateChange = (date) => {
        const newEndDate = date.toISOString().split('T')[0];
        setEndDate(newEndDate);
    
        const minStartDate = new Date(date);
        minStartDate.setDate(minStartDate.getDate() - 1);
        const minStartDateString = minStartDate.toISOString().split('T')[0];
    
        if (startDate > minStartDateString) {
            setStartDate(minStartDateString);
        }
    }

    const validate = (data) => {
        let errors = {}
        if (!data.vehicleId) errors.vehicleId = 'Vehicle is required'
        if (!data.memberId) errors.memberId = 'Member is required'
        if (!data.startDate) errors.startDate = 'Start Date is required'
        if (!data.endDate) errors.endDate = 'End Date is required'
        if (!data.collectionTime) errors.collectionTime = 'Collection Time is required'
        if (!data.returnTime) errors.returnTime = 'Return Time is required'
        if (!data.bookingStatus) errors.bookingStatus = 'Booking Status is required'
        if (!data.bookingTerm) errors.bookingTerm = 'Booking Term is required'
        return errors
    }

    const handleBooking = async (e) => {
        e.preventDefault()       
        if (!authMember) {
            navigate('/register')
        } else {
            try {
                let formData = {
                    vehicleId,
                    'memberId': authMember.id,
                    startDate,
                    endDate,
                    collectionTime,
                    returnTime,
                    'bookingStatus': BOOKING_STATUS_PENDING,
                    'bookingTerm': BOOKING_SHORT_TERM,
                    createInvoice
                }
                const validationErrors = validate(formData)
                setErrors(validationErrors)
                if (Object.keys(validationErrors).length === 0) {
                    const result = await dispatch(createBooking(formData))
                    navigate('/account/booking')
                }
            } catch (error) {
                if (error.code == 400) {
                    let errors = {};
                    for (let key in error.messages) {
                        if (error.messages.hasOwnProperty(key)) {
                            error.messages[key].forEach(msg => {
                                if (key == 'vehicle_id') errors.vehicleId = msg
                                if (key == 'member_id') errors.memberId = msg
                                if (key == 'start_date') errors.startDate = msg
                                if (key == 'end_date') errors.endDate = msg
                                if (key == 'collection_time') errors.collectionTime = msg
                                if (key == 'return_time') errors.returnTime = msg
                                if (key == 'booking_status') errors.bookingStatus = msg
                                if (key == 'booking_term') errors.bookingTerm = msg
                                if (key == 'create_invoice') errors.createInvoice = msg
                                setErrors(errors)
                            })
                        }
                    }
                    toast.error('Validation error.')
                } else {
                    toast.error('Server error.')
                }
            }
        }
    }

    return <>
        <Card>
            <CardHeader style={{ fontWeight:'700px', fontSize:'1.3rem', textAlign:'center'}}>Book the car</CardHeader>
            <CardBody>
                <Form>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <div className="d-flex flex-column flex-md-row align-items-md-start align-items-lg-center mt-md-2">
                                <Label htmlFor='startDate' className="form-label" style={{ width: '150px' }}>Start Date</Label>
                                <div style={{ flexGrow: 1 }}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control custom-date-picker"
                                        id="startDate"
                                        name="start_date"
                                        dateFormat="yyyy-MM-dd"
                                        filterDate={date => filterDate(date, getHolidayDates(holidays), getUnavailbeDays(unavailabledays))}
                                    />
                                    {errors.startDate && <p className='validation__error-message'>{errors.startDate}</p>}
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" md="12" sm="12">
                            <div className="d-flex flex-column flex-md-row align-items-md-start align-items-lg-center mt-md-3">
                                <Label htmlFor='endDate' className="form-label" style={{ width: '150px' }}>End Date</Label>
                                <div style={{ flexGrow: 1 }}>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        className="form-control custom-date-picker"
                                        id="endDate"
                                        name="end_date"
                                        dateFormat="yyyy-MM-dd"
                                        filterDate={date => filterDate(date, getHolidayDates(holidays), getUnavailbeDays(unavailabledays))}
                                    />
                                    {errors.endDate && <p className='validation__error-message'>{errors.endDate}</p>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12" sm="12">
                            <div className="d-flex flex-column flex-md-row align-items-md-start align-items-lg-center mt-md-3">
                                <Label htmlFor='collectionTime' className="form-label" style={{ width: '150px' }}>Collection Time</Label>
                                <div style={{ flexGrow: 1 }}>
                                    <select 
                                        name="collectionTime" 
                                        id="collectionTime" 
                                        className="form-control" 
                                        value={collectionTime}
                                        onChange = { data => setCollectionTime(data.target.value) }
                                    >
                                        <option value='3pm'>3pm</option>
                                        <option value='4pm'>4pm</option>
                                        <option value='5pm'>5pm</option>
                                    </select>
                                    {errors.collectionTime && <p className='validation__error-message'>{errors.collectionTime}</p>}
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" md="12" sm="12">
                            <div className="d-flex flex-column flex-md-row align-items-md-start align-items-lg-center mt-md-3">
                                <Label htmlFor='returnTime' className="form-label" style={{ width: '150px' }}>Return Time</Label>
                                <div style={{ flexGrow: 1 }}>
                                    <select 
                                        name="returnTime" 
                                        id="returnTime" 
                                        className="form-control" 
                                        value={returnTime}
                                        onChange= { data => setReturnTime(data.target.value) }
                                    >
                                        <option value='3pm'>3pm</option>
                                        <option value='4pm'>4pm</option>
                                        <option value='5pm'>5pm</option>
                                    </select>
                                    {errors.returnTime && <p className='validation__error-message'>{errors.returnTime}</p>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col lg="12" md="12" sm="12">
                            <Label>
                                <span style={{ display: 'inline-block', paddingRight: '15px' }}> Your Subscriptions </span> 
                                <span style={{ paddingRight: '15px' }}>{ startDate } - { endDate }</span>
                                <span> <b>{ totalPrice } S$</b> </span>
                            </Label>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="12" md="12" sm="12" className="d-flex justify-content-center">
                            <Button className="btn btn-success w-100" onClick={ handleBooking }>Booking</Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card>
        <ToastContainer />
    </>

}

export default BookingForm