const serviceData = [
    {
        id: 1,
        title: "Long-Term and Short-Term Rentals Tailored for You",
        icon: "ri-roadster-line",
        desc: "We understand that every customer’s needs are unique, which is why our flexible options cater to every requirement, from weekend car rentals to monthly rentals. Our versatile fleet is equipped to provide you with the ideal vehicle, whether you need it just for a weekend or for an extended duration.",
    },
    {
        id: 2,
        title: "Transparent Pricing, No Hidden Costs",
        icon: "ri-shield-check-fill",
        desc: "With Boss Car Leasing, what you see is what you get. Our all-inclusive pricing covers everything you need, ensuring a surprise-free experience. This transparency allows you to enjoy your car rental in Singapore with complete peace of mind.",
    },
    {
        id: 3,
        title: "Seamless Booking, Outstanding Value",
        icon: "ri-bank-card-line",
        desc: "Booking your rental with Boss Car Leasing is straightforward and offers unmatched value. Our user-friendly website and dedicated customer support streamline the process, ensuring your car rental in Singapore is hassle-free.",
    }
]

export default serviceData;