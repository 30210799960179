import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const VehiclePagination = ({ currentPage, totalPages, onPageChange }) => {

    const handleClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <Pagination aria-label="Page navigation example">
            <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink first href="#" onClick={() => handleClick(1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage <= 1}>
                <PaginationLink previous href="#" onClick={() => handleClick(currentPage - 1)} />
            </PaginationItem>
            {[...Array(totalPages)].map((_, index) => (
                <PaginationItem active={index + 1 === currentPage} key={index}>
                    <PaginationLink href="#" onClick={() => handleClick(index + 1)}>
                        {index + 1}
                    </PaginationLink>
                </PaginationItem>
            ))}
            <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink next href="#" onClick={() => handleClick(currentPage + 1)} />
            </PaginationItem>
            <PaginationItem disabled={currentPage >= totalPages}>
                <PaginationLink last href="#" onClick={() => handleClick(totalPages)} />
            </PaginationItem>
        </Pagination>
    );
};

export default VehiclePagination;
