import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { signIn } from '../actions/authAction.js'
import { useNavigate, Link } from 'react-router-dom'
import { clearResetPasswordNotification, clearRemoveMemberNotification } from './../reducers/authSlice.js'

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loading = useSelector(state => state.auth.loading)
    const resetPasswordNotification = useSelector((state) => state.auth.resetPasswordNotification)
    const removeMemberNotification = useSelector(state => state.auth.removeMemberNotification)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})

    const validate = (data) => {
        let errors = {}
        if (!data.email) errors.email = 'Email is required'
        if (!data.password) errors.password = 'Password is required'
        return errors
    }

    useEffect(() => {
        if (resetPasswordNotification) {
            toast.success('Password Changed Successfully!')
            dispatch(clearResetPasswordNotification());
        }
    }, [resetPasswordNotification, dispatch]);

    useEffect(() => {
        if (removeMemberNotification) {
            toast.success('Member Removed Successfully!')
            dispatch(clearRemoveMemberNotification());
        }
    }, [removeMemberNotification, dispatch]);

    const handleLogin = async (e) => {
        e.preventDefault()
        try {
            let formData = {
                email,
                password
            }
            const validationErrors = validate(formData)
            setErrors(validationErrors)
            if (Object.keys(validationErrors).length === 0) {
                const result = await dispatch(signIn(formData))
                navigate('/')
            }
        } catch (error) {
            if (error.code == 400) {
                let errors = {};
                for (let key in error.messages) {
                    if (error.messages.hasOwnProperty(key)) {
                        error.messages[key].forEach(msg => {
                            if (key == 'error') errors.error = msg
                            if (key == 'email') errors.email = msg
                            if (key == 'password') errors.password = msg
                            setErrors(errors)
                        })
                    }
                }
                if ( errors.error ) {
                    toast.error(errors.error)
                } else {
                    toast.error('Validation error.')
                }
                
            } else {
                toast.error('Server error.')
            }
        }

    };

    return <section>
        <Container>
            <Row className='justify-content-center'>
                <Col lg="5" md="5" sm="12" className='mt-2 mb-2'>
                    <Card className='mt-5 mb-5'>
                        <CardHeader>
                            Login
                        </CardHeader>
                        <CardBody>
                            <Form autoComplete="off">
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input 
                                        type="email" 
                                        id="email" 
                                        name="email"
                                        value={email}
                                        onChange={ e => setEmail(e.target.value)}
                                    />
                                    {errors.email && <p className='validation__error-message'>{errors.email}</p>}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <Input 
                                        type="password" id="password" 
                                        name="password"
                                        value={password}
                                        onChange= { e => setPassword(e.target.value)}
                                    />
                                    {errors.password && <p className='validation__error-message'>{errors.password}</p>}
                                </FormGroup>
                                {/* <FormGroup>
                                    <input type="checkbox" id="remember_me" style={{ marginRight: '5px'}} name="remember_me"/>
                                    <Label for="remember_me">Remember Me</Label>
                                </FormGroup> */}
                                <FormGroup className='d-flex align-items-center gap-3'>
                                    <button className='btn btn-success' onClick={handleLogin} disabled={loading}>
                                    {loading ? 'Login...' : 'Login'}
                                    </button>
                                    <Link to="/" className='btn' style={{ background: '#a9a1a1'}}>Go Back Home</Link>
                                </FormGroup>
                                <FormGroup>
                                    <Link to="/forgot-password" style={{ fontWeight: '700', cursor: 'pointer' }}>Lost your password?</Link>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default Login