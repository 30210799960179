import React from 'react'
import { NavLink } from 'react-router-dom'
import { Label } from 'reactstrap'
import '../../styles/side-nav-menu.css'

const SideNavMenu = () => {

    return <ul className='side__menu' >
        <li>
            <NavLink to="/account/profile" className={ navClass => navClass.isActive ? 'side__nav-active' : ''}>
                <i className="ri-id-card-fill"/>
                <Label> My Account </Label>
            </NavLink>
        </li>
        <li>
            <NavLink to="/account/booking"  className={ navClass => navClass.isActive ? 'side__nav-active' : ''}>
                <i className="ri-profile-fill"/>
                <Label> My Booking </Label>
            </NavLink>
        </li>
    </ul>
}

export default SideNavMenu