import { API_URL } from '../config/constants/common.js'
import {
    vendorListPending,
    vendorListSuccess,
    vendorListFailure,
} from '../reducers/vendorSlice'
import { memberAuthHeader, authHeader } from '../utils/authHeaders.js'

export const getVendorList = () => async( dispatch ) => {
    dispatch(vendorListPending())

    return fetch(`${API_URL}vendors`, {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(vendorListSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(vendorListFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(vendorListFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(vendorListFailure(error.message))
        return Promise.reject(error)
    })
}