// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile__picture {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    font-size: 10rem;
    border-right: 1px solid #ddd;
}
.profile__footer {
    display: flex;
    border-top: 1px solid rgb(221, 221, 221);
    padding-top: 15px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
@media only screen and (max-width: 768px) {
    .profile__footer  {
        flex-direction: column;
    }
    .profile__footer > button {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,4BAA4B;AAChC;AACA;IACI,aAAa;IACb,wCAAwC;IACxC,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;AACb;AACA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".profile__picture {\n    display: flex;\n    align-items: center;\n    height: 100%;\n    justify-content: center;\n    font-size: 10rem;\n    border-right: 1px solid #ddd;\n}\n.profile__footer {\n    display: flex;\n    border-top: 1px solid rgb(221, 221, 221);\n    padding-top: 15px;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n}\n@media only screen and (max-width: 768px) {\n    .profile__footer  {\n        flex-direction: column;\n    }\n    .profile__footer > button {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
