import React, { useEffect } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import HeroSlider from "../components/UI/HeroSlider"
import Helmet from "../components/Helmet/Helmet"
import FindCardForm from '../components/UI/FindCarForm'
import AboutSection from '../components/UI/AboutSection'
import ServicesList from '../components/UI/ServicesList'
import CarItem from '../components/UI/CarItem'

import { getVehicleList } from '../actions/vehicleAction.js'

const Home = () => {
    const dispatch = useDispatch()
    const { data: vehicles } = useSelector(state => state.vehicle)

    useEffect(() => {
        dispatch(getVehicleList({
            page: 1,
            'vendor_id': '',
            'type': '',
            'sort_price': '',
            'sort': ''
        }))
    }, [dispatch])

    return <Helmet title="Home">
        {/* ============ hero section ============*/}
        <section className="p-0 hero__slider-section">
            <HeroSlider />
            <div className='hero__form'>
                <Container>
                    <Row className='form__row'>
                        <Col lg="4" md="4">
                            <div className='find__cars-left'>
                                <h2>Find your best car here</h2>
                            </div>
                        </Col>
                        <Col lg='8' md='8' sm='12'>
                            <FindCardForm />
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
        {/*===================== about section =====================*/}
        <AboutSection />
        {/*===================== about section =====================*/}
        <section>
            <Container>
                <Row>
                    <Col lg="12" className='mb-4 text-center'>
                        <h6 className='section__subtitle'>See Our</h6>
                        <h2 className='section__title'>Popular Services</h2>
                    </Col>
                    <ServicesList />
                </Row>
            </Container>
        </section>
        {/*===================== car offer section =====================*/}
        <section>
            <Container>
                <Row>
                    <Col lg='12' className='text-center mb-2'>
                        <h6 className='section__subtitle'>Come with</h6>
                        <h2 className='section__title'>Hot Offers</h2>
                    </Col>
                    <Row>
                    {
                        vehicles.slice(0, 3).map(item => (
                            <CarItem item={item} key={item.id} />
                        ))
                    }
                    </Row>
                </Row>
            </Container>
        </section>
    </Helmet>
}

export default Home