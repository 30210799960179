import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const MemberRoute = ({ children }) => {
    const [memberObj, setMemberObj] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const memberData = JSON.parse(localStorage.getItem('member'));
        setMemberObj(memberData);
        if (!memberData || !memberData.token) {
            navigate('/login', { state: { from: location } });
        }
    }, [navigate, location]);

    return memberObj && memberObj.token ? children : null;
};

export default MemberRoute;

