import React, { useState } from 'react'
import { Container, Row, Col, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword } from '../actions/authAction.js'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const loading = useSelector(state => state.auth.loading)

    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})

    const validate = (data) => {
        let errors = {}
        if (!data.email) errors.email = 'Email is required'
        return errors
    }

    const handleForgotPassword = async (e) => {
        e.preventDefault()
        try {
            let formData = { email }
            const validationErrors = validate(formData)
            setErrors(validationErrors)
            if (Object.keys(validationErrors).length === 0) {
                let result = await dispatch(forgotPassword(formData))
                toast.success('The password reset link has been successfully sent to your email!.')
                setEmail('')
            }
        } catch (error) {
            if (error.code == 400) {
                let errors = {};
                for (let key in error.messages) {
                    if (error.messages.hasOwnProperty(key)) {
                        error.messages[key].forEach(msg => {
                            if (key == 'email') errors.email = msg
                            setErrors(errors)
                        })
                    }
                }
                toast.error('Validation error.')
            } else {
                toast.error('Server error.')
            }
        }
    }

    return <section>
        <Container>
            <Row className='justify-content-center'>
                <Col lg="5" md="5" sm="12" className='mt-2 mb-2'>
                    <Card className='mt-5 mb-5'>
                        <CardHeader>
                            Forgot Password
                        </CardHeader>
                        <CardBody>
                            <Form autoComplete="off">
                                <FormGroup>
                                    <Label for="email">Email</Label>
                                    <Input 
                                        type="email" 
                                        id="email" 
                                        name="email"
                                        value={email}
                                        onChange={ e => setEmail(e.target.value)}
                                    />
                                    {errors.email && <p className='validation__error-message'>{errors.email}</p>}
                                </FormGroup>
                                
                                <FormGroup className='d-flex align-items-center gap-3'>
                                    <button className='btn btn-success' onClick={handleForgotPassword} disabled={loading}>
                                    {loading ? 'Get New Password...' : 'Get New Password'}
                                    </button>
                                    <Link to='/login' className='btn' style={{ background: '#a9a1a1'}}>Go To Login</Link>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
        <ToastContainer />
    </section>
}

export default ForgotPassword