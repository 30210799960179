// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact__top {
    padding-top: 35px;
    padding-bottom: 35px;
    background-image: linear-gradient(to right, #ddd, #03A5D5);
    margin-top: -50px;
}
.contact__top h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 15px;
}
.contact__map {
    width: 60%;
    min-height: 400px;
}
.contact__info {
    width: 40%;
    background: #fff;
    padding-left: 20px;
    padding-top: 30px;
}
.contact__number i {
    font-size: 1.8rem;
    margin-bottom: 15px;
    display: inline-block;
    color: #03A5D5;
}
.contact__number p {
    font-size: 1.3rem;
    font-weight: 700;
    color: #03A5D5;
}
@media only screen and (max-width: 768px) {
    .contact__map {
        width: 100%;
        height: 400px;
    }
    .contact__info {
        width: 100%;
    }
    .contact__middle {
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/contactus.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,0DAA0D;IAC1D,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,gBAAgB;IAChB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,WAAW;IACf;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".contact__top {\n    padding-top: 35px;\n    padding-bottom: 35px;\n    background-image: linear-gradient(to right, #ddd, #03A5D5);\n    margin-top: -50px;\n}\n.contact__top h3 {\n    font-size: 2rem;\n    font-weight: 600;\n    margin-bottom: 15px;\n}\n.contact__map {\n    width: 60%;\n    min-height: 400px;\n}\n.contact__info {\n    width: 40%;\n    background: #fff;\n    padding-left: 20px;\n    padding-top: 30px;\n}\n.contact__number i {\n    font-size: 1.8rem;\n    margin-bottom: 15px;\n    display: inline-block;\n    color: #03A5D5;\n}\n.contact__number p {\n    font-size: 1.3rem;\n    font-weight: 700;\n    color: #03A5D5;\n}\n@media only screen and (max-width: 768px) {\n    .contact__map {\n        width: 100%;\n        height: 400px;\n    }\n    .contact__info {\n        width: 100%;\n    }\n    .contact__middle {\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
