import React from 'react'
import { Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import '../../styles/car-item.css'
import { SERVER_URL } from '../../config/constants/common.js'
import vehicleTypes from '../../config/constants/vehicleTypes.js'

const CarItem = (props) => {
    const { id, image, name, seats, license_plate, price, type } = props.item
    const imgUrl = `${SERVER_URL}${image}`

    return <Col lg='4' md='4' sm='6' className='mb-5'>
        <div className='car__item'>
            <div className=''>
                <img src={imgUrl} alt='' className='w-100 car__img'/>
            </div>
            <div className='car__item-content mt-4'>
                <h4 className='section__title text-center'> { name }</h4>
                <h6 className='rent__price text-center'>{ price }<span>/Day</span></h6>
                <div className='car__item-info d-flex align-items-center justify-content-between mt-3 mb-4'>
                    <span className='d-flex align-items-center gap-1'><i className="ri-sofa-line"></i>{ seats }</span>
                    <span className='d-flex align-items-center gap-1'><i className='ri-settings-2-line'></i>{ `${vehicleTypes[type]}` }</span>
                    {/* <span className='d-flex align-items-center gap-1'><i className="ri-car-line"></i>{ license_plate }</span> */}
                </div>
                {/* <button className="w-50 car__item-btn car__btn-rent" style={{ padding: '0px' }}>
                    <Link to={`/vehicles/${id}`} style={{ width: '100%', display: 'inline-block', height: '100%', padding: '8px 0px'}}> Rent </Link>
                </button> */}
                <button className="w-100 car__item-btn car__btn-details" style={{ padding: '0px' }}>
                    <Link to={`/vehicles/${id}`} style={{ width: '100%', display: 'inline-block', height: '100%', padding: '8px 0px'}}> Rent Now </Link>
                </button>
            </div>
        </div>
    </Col>
}

export default CarItem