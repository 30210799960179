import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import '../../styles/about-section.css'
import aboutImg from '../../assets/all-images/red_mercedes.jpeg'

const AboutSection = ({aboutClass}) => {
    return <section className='about__section' style={ aboutClass === 'aboutPage' 
                                                        ? {marginTop: '0px'} 
                                                        : { marginTop: '90px'}}
            >
        <Container>
            <Row>
                <Col lg="6" md="6">
                    <div className='about__section-content'>
                        <h4 className='section__subtitle'>About Us</h4>
                        <h2 className='section__title'>Looking for cheap and budget car rental in Singapore?</h2>
                        <p className='section__description'>
                        Boss Car Leasing provides cheap car rental & leasing services that offer weekend car rental for both long-term and short-term car rental needs at the most reasonable rental rates.

                        When it comes to finding a budget car rental in Singapore, our fleet stands out with a wide variety of options, ensuring that every customer finds the perfect vehicle to suit their needs.

                        For those seeking a Singapore cheap car rental solution, our competitive pricing and high-quality fleet make us the go-to choice. We pride ourselves on offering the cheapest car rental in Singapore, combining affordability with reliability and excellent customer service. We offer the best rates, with no hidden costs or deposit requirements. Our transparent pricing ensures that you know exactly what you’re paying. 
                        </p>
                    </div>
                    {/* <div className='about__section-item d-flex align-items-center'>
                        <p className='section__description d-flex align-items-center gap-2'>
                            <i className='ri-checkbox-circle-line'>Lorem ipsum dolor sit amet</i>
                        </p>
                        <p className='section__description d-flex align-items-center gap-2'>
                            <i className='ri-checkbox-circle-line'>Lorem ipsum dolor sit amet</i>
                        </p>
                    </div>
                    <div className='about__section-item d-flex align-items-center'>
                        <p className='section__description d-flex align-items-center gap-2'>
                            <i className='ri-checkbox-circle-line'>Lorem ipsum dolor sit amet</i>
                        </p>
                        <p className='section__description d-flex align-items-center gap-2'>
                            <i className='ri-checkbox-circle-line'>Lorem ipsum dolor sit amet</i>
                        </p>
                    </div> */}
                </Col>
                <Col lg="6" md="6">
                    <div className='about__img'>
                        <img src={aboutImg} alt="" style={{width: '100%', height: 'auto'}}/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
}

export default AboutSection