import React, { useState} from 'react'

const SortorderDropdown = ({ onSortOrderChange, selectedValue='' }) => {
    const handleChange = (event) => {
        onSortOrderChange(event.target.value)
    }

    return (
        <select name="sort_order" id="sort_order" value={selectedValue} onChange={handleChange}>
            <option value="" disabled>Select an option</option>
            <option value="asc">ASC</option>
            <option value="desc">DESC</option>
        </select>
    )
}

export default SortorderDropdown