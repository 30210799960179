import { createSlice } from '@reduxjs/toolkit'

const companySlice = createSlice({
    name: 'company',
    initialState: {
        data: {},
        loading: false,
        error: null,
    },
    reducers: {
        companyDataPending: (state) => {
            state.loading = true
            state.error = null
        },
        companyDataSuccess: (state, action) => {
            state.loading = false;
            state.data = action.payload;
        },
        companyDataFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const { 
    companyDataPending, 
    companyDataSuccess, 
    companyDataFailure, 
} = companySlice.actions

export default companySlice.reducer