import { API_URL } from '../config/constants/common.js'
import { memberAuthHeader, authHeader } from '../utils/authHeaders.js'
import {
    registrationPending,
    registrationSuccess,
    registrationFailure,
    loginPending,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    forgotPasswordPending,
    forgotPasswordSuccess,
    forgotPasswordFailure,
    resetPasswordPending,
    resetPasswordSuccess,
    resetPasswordFailure,
    updateMemberPending,
    updateMemberSuccess,
    updateMemberFailure,
    removeMemberPending,
    removeMemberSuccess,
    removeMemberFailure
} from '../reducers/authSlice'

export const signup = (newData) => async (dispatch) => {
    dispatch(registrationPending())

    const formData = new URLSearchParams()
    formData.append('name', newData.name)
    formData.append('email', newData.email)
    formData.append('username', newData.name.toLowerCase())
    formData.append('is_bad_debt', newData.isBadDebt ? '1' : '0')
    formData.append('contact', newData.contact)
    formData.append('nric', newData.nric)
    formData.append('password', newData.password)
    formData.append('password_confirmation', newData.confirmPassword)

    return fetch(API_URL + 'register', {
        method: 'POST',
        headers: authHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(registrationSuccess(newData))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(registrationFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(registrationFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(registrationFailure(error.message))
        return Promise.reject(error)
    })
}
export const signIn = (data)  => async (dispatch) => {
    dispatch(loginPending)

    const formData = new URLSearchParams()
    formData.append('email', data.email)
    formData.append('password', data.password)

    return fetch(API_URL + 'login', {
        method: 'POST',
        headers: authHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(loginSuccess(result.data.data))
            localStorage.setItem('member',JSON.stringify({
                id: result.data.data.id ,
                name: result.data.data.name, 
                email: result.data.data.email,
                userName: result.data.data.username,
                is_bad_debt: result.data.data.is_bad_debt,
                contact: result.data.data.contact,
                nric: result.data.data.nric,
                token: result.data.token 
            }))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(loginFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(loginFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(loginFailure(error.message))
        return Promise.reject(error)
    })
}
export const logout = ()  => async (dispatch) => {
    dispatch(logoutSuccess())
}
export const forgotPassword = (data) => async (dispatch) => {
    dispatch((forgotPasswordPending()))

    const formData = new URLSearchParams()
    formData.append('email', data.email)
    formData.append('app_type', 'web')

    return fetch(API_URL + 'forgot-password', {
        method: 'POST',
        headers: authHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(forgotPasswordSuccess(data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(forgotPasswordFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(forgotPasswordFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(forgotPasswordFailure(error.message))
        return Promise.reject(error)
    })
}
export const resetPassword = (data) => async (dispatch) => {
    dispatch(resetPasswordPending())

    const formData = new URLSearchParams()
    formData.append('token', data.token)
    formData.append('email', data.email)
    formData.append('new_password', data.newPassword)
    formData.append('confirm_password', data.confirmPassword)

    return fetch(API_URL + 'reset-password', {
        method: 'POST',
        headers: authHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(resetPasswordSuccess())
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(resetPasswordFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(resetPasswordFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(resetPasswordFailure(error.message))
        return Promise.reject(error)
    })
}
export const resetAccountPassword = (data) => async (dispatch) => {
    dispatch(resetPasswordPending())

    const formData = new URLSearchParams()
    formData.append('email', data.email)
    formData.append('new_password', data.newPassword)
    formData.append('confirm_password', data.confirmPassword)

    return fetch(API_URL + 'reset-account-password', {
        method: 'POST',
        headers: authHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(resetPasswordSuccess())
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(resetPasswordFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(resetPasswordFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(resetPasswordFailure(error.message))
        return Promise.reject(error)
    })
}
export const updateMember = (newData) => async (dispatch) => {
    dispatch(updateMemberPending())

    const formData = new URLSearchParams()
    formData.append('id', newData.id)
    formData.append('name', newData.name)
    formData.append('email', newData.email)
    formData.append('username', newData.userName)
    formData.append('is_bad_debt', newData.isBadDebt ? '1' : '0')
    formData.append('contact', newData.contact)
    formData.append('nric', newData.nric)

    return fetch(API_URL + 'edit-member', {
        method: 'POST',
        headers: memberAuthHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            const storedMember = JSON.parse(localStorage.getItem('member'))   
            const updatedMember = {
                ...storedMember,
                name: newData.name,
                email: newData.email,
                userName: newData.userName,
                is_bad_debt: newData.isBadDebt,
                contact: newData.contact,
                nric: newData.nric,
            }
            localStorage.setItem('member', JSON.stringify(updatedMember))

            dispatch(updateMemberSuccess(updatedMember))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(updateMemberFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(updateMemberFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(updateMemberFailure(error.message))
        return Promise.reject(error)
    })
}
export const removeMember = (memberId) => async(dispatch) => {
    dispatch(removeMemberPending)

    const formData = new URLSearchParams()
    formData.append('id', memberId)

    return fetch(API_URL + 'delete-member', {
        method: 'POST',
        headers: memberAuthHeader(),
        body: formData
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(removeMemberSuccess())
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(removeMemberFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(removeMemberFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(removeMemberFailure(error.message))
        return Promise.reject(error)
    })
}