import React from 'react'
import Slider from 'react-slick'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'

import '../../styles/hero-slider.css'

const HeroSlider = () => {
    const settings = {
        fade: true,
        speed: 2000,
        autoplaySpeed: 3000,
        infinite: true,
        autoplay: true,
        sliedsToShow: 1,
        sliedsToScroll: 1,
        pauseOnHover: false
    }
    return <Slider {...settings} className='hero__slider'>
        <div className='slider__item slider__item-01 mt0'>
            <Container>
                <div className='slider__content'>
                    <h1 className='text-light mb-2'>Boss Car Leasing</h1>
                    <h3 className='text-light mb-2'>We Rent Boss Cars</h3>
                    <h6 className='text-light mb-2'>Boss Car Leasing offers budget-friendly car rental and leasing services in Singapore</h6>
                </div>
            </Container>
        </div>
        <div className='slider__item slider__item-02 mt0'>
            <Container>
                <div className='slider__content'>
                    <h1 className='text-light mb-2'>Boss Car Leasing</h1>
                    <h3 className='text-light mb-2'>We Rent Boss Cars</h3>
                    <h6 className='text-light mb-2'>Boss Car Leasing offers budget-friendly car rental and leasing services in Singapore</h6>
                </div>
            </Container>
        </div>
        <div className='slider__item slider__item-03 mt0'>
            <Container>
                <div className='slider__content'>
                    <h1 className='text-light mb-2'>Boss Car Leasing</h1>
                    <h3 className='text-light mb-2'>We Rent Boss Cars</h3>
                    <h6 className='text-light mb-2'>Boss Car Leasing offers budget-friendly car rental and leasing services in Singapore</h6>
                </div>
            </Container>
        </div>
    </Slider>
}

export default HeroSlider