import { createSlice } from '@reduxjs/toolkit'

const bookingSlice = createSlice({
    name: 'booking',
    initialState: {
        bookingDays: [],
        createBookingMessage: false,
        memberBookings: [],
        loading: false,
        error: null,
    },
    reducers: {
        bookingDaysPending: (state) => {
            state.loading = true
            state.error = null
        },
        bookingDaysSuccess: (state, action) => {
            state.loading = false;
            state.bookingDays = action.payload;
        },
        bookingDaysFailure: (state, action) => {
            state.loading = false
            state.error = action.payload
        },
        createBookingPending: (state) => {
            state.loading = true
            state.error = null
            state.createBookingMessage = false
        },
        createBookingSuccess: (state) => {
            state.loading = false
            state.createBookingMessage = true
        },
        createBookingFailure: (state, action) => {
            state.loading =false
            state.error = action.payload
        },
        resetBookingMessage: (state) => {
            state.createBookingMessage = false
        },
        memberBookingsPending: (state) => {
            state.loading = true 
            state.error = null
        },
        memberBookingsSuccess: (state, action) => {
            state.loading = false 
            state.memberBookings = action.payload.data
            state.currentPage = action.payload.current_page;
            state.totalPages = action.payload.last_page;
        },
        memberBookingsFailure: (state, action) => {
            state.loading = false 
            state.error = action.payload
        }
    }
})

export const { 
    bookingDaysPending, 
    bookingDaysSuccess, 
    bookingDaysFailure,
    createBookingPending,
    createBookingSuccess,
    createBookingFailure,
    resetBookingMessage,
    memberBookingsPending,
    memberBookingsSuccess,
    memberBookingsFailure
} = bookingSlice.actions

export default bookingSlice.reducer