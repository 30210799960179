import React, { useState } from 'react';
import Select from 'react-select';

const VehicleNameSearch = ({ vehicles, onSelect, selectedValue }) => {
    const options = vehicles.map(vehicle => ({
        value: vehicle.value,
        label: vehicle.name,
    }));

    const handleChange = (selectedOption) => {
        onSelect(selectedOption);
    };

    const selectedValueOption = selectedValue
                                ? { value: selectedValue, label: selectedValue }
                                : null;
    return (
        <Select
            options={options}
            onChange={handleChange}
            placeholder="Select an option"
            value={selectedValueOption}
        />
    );
};

export default VehicleNameSearch;
