import React, { useRef, useState, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Container, Row, Col, Button } from 'reactstrap'
import 'remixicon/fonts/remixicon.css';
import './../../styles/header.css'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from './../../actions/authAction.js';
import { fetchCompanyData } from './../../actions/companyAction.js'
import { SERVER_URL } from '../../config/constants/common.js'

import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

const navLinks = [
    {
        path: '/',
        display: 'Home'
    },
    {
        path: "/aboutus",
        display: 'AboutUs'
    },
    {
        path: "/vehicles",
        display: 'Vehicles'
    },
    {
        path: "/contactus",
        display: "ContactUs"
    }
]
const Header = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authMember = useSelector(state => state.auth.authMember)
    const { data: company } = useSelector(state => state.company)
    const menuRef = useRef(null)

    const toggleMenu = () => menuRef.current.classList.toggle('menu__active')

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const LogoImg = `${SERVER_URL}${company.logo}`

    useEffect(() => {
        dispatch(fetchCompanyData())
    }, [dispatch])

    useEffect(() => {
        const faviconLink = document.querySelector("link[rel*='icon']") || document.createElement('link');
        faviconLink.type = 'image/x-icon';
        faviconLink.rel = 'shortcut icon';
        faviconLink.href = LogoImg;
        document.getElementsByTagName('head')[0].appendChild(faviconLink);
    }, [LogoImg])

    const handleLogout = (e) => {
        e.preventDefault()
        dispatch(logout())
        navigate('/')
    };

    return (
        <header className="header">
            {/*============ header top ============*/}
            <div className="header__top">
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="6">
                            <div className="header__top__left">
                                <span>Need Help?</span>
                                <span className="header__top__help">
                                    <i className="ri-phone-fill"></i> +65 6748 9747, +65 8876 8853
                                </span>
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="6">
                            <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                                {
                                    authMember == null ?
                                        <>
                                            <Link to='/login' className="d-flex align-items-center gap-1"><i className="ri-login-circle-line"></i>Login</Link>
                                            <Link to='/register' className="d-flex align-items-center gap-1"><i className="ri-user-line"></i>Register</Link>
                                        </> :
                                        <>
                                            <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                                <DropdownToggle caret className="no-hover-effect">
                                                    <i className="ri-user-fill"></i> MY ACCOUNT
                                                </DropdownToggle>
                                                <DropdownMenu >
                                                    <DropdownItem>
                                                        <NavLink to="/account/profile" style={{ color: '#000', display: 'flex', alignItems: 'center' }} className={navClass => navClass.isActive ? 'side__nav-active' : ''}>
                                                            <i className="ri-id-card-fill" style={{
                                                                marginRight: '5px',
                                                                color: '#0074D9',
                                                                fontSize: '1.5rem'
                                                            }}
                                                            ></i>
                                                            <label> PROFILE </label>
                                                        </NavLink>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <NavLink to="/account/booking" style={{ color: '#000', display: 'flex', alignItems: 'center' }} className={navClass => navClass.isActive ? 'side__nav-active' : ''}>
                                                            <i className="ri-profile-fill" style={{
                                                                marginRight: '5px',
                                                                color: '#0074D9',
                                                                fontSize: '1.5rem'
                                                            }}></i>
                                                            <label> DASHBOARD </label>
                                                        </NavLink>
                                                    </DropdownItem>
                                                    <DropdownItem divider />
                                                    <DropdownItem>
                                                        <a onClick={ handleLogout } className='d-flex align-items-center' style={{ color: '#000', border:'none', background:'initial' }}>
                                                            <i className="ri-arrow-right-circle-fill" style={{
                                                                marginRight: '5px',
                                                                color: '#FF4136',
                                                                fontSize: '1.5rem'
                                                            }}></i>
                                                            <label>LOGOUT</label>
                                                        </a>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*============ header middle ============*/}
            <div className="header__middle">
                <Container>
                    <Row>
                        <Col lg="3" md="3" sm="4">
                            <div className="logo">
                                <h1>
                                    <Link to="/" className="d-flex align-items-center gap-3">
                                        <img src={LogoImg} style={{ width: '120px', height: 'auto'}}/>
                                    </Link>
                                </h1>
                            </div>
                        </Col>
                        <Col lg='3' md='3' sm='4'>
                            <div className="header__location d-flex align-items-center gap-2">
                                <span><i className="ri-earth-line"></i></span>
                                <div className="header__location-content">
                                    <h4>Singapore</h4>
                                    <h6>155 Kaki Bukit Ave 1, Level 2 Shun Li Industrial Park, S(416012)</h6>
                                </div>
                            </div>
                        </Col>
                        <Col lg='3' md='3' sm='4'>
                            <div className="header__location d-flex align-items-center gap-2">
                                <span><i className="ri-time-line"></i></span>
                                <div className="header__location-content">
                                    <h4>Monday to Sunday</h4>
                                    <h6>9:00am - 6:00pm</h6>
                                </div>
                            </div>
                        </Col>
                        <Col lg="3" md="3" sm="0" style={{ textAlign: 'right'}}>
                            <a href={`tel:+65 6748 9747`}>
                                <Button color="success">Request a Call</Button>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*============== main navigation ==============*/}
            <div className="main__navbar">
                <Container>
                    <div className="navigation_wrapper d-flex align-items-center justify-content-between">
                        <span className="mobile__menu">
                            <i className="ri-menu-line" onClick={toggleMenu}></i>
                        </span>
                        <div className="navigation" ref={menuRef} onClick={toggleMenu}>
                            <div className="menu">
                                {
                                    navLinks.map((item, index) => (
                                        <NavLink to={item.path} className={navClass => navClass.isActive ? 'nav__active nav__item' : 'nav__item'} key={index}>{item.display}</NavLink>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="nav__right"></div>
                    </div>
                </Container>
            </div>
        </header>
    );
};

export default Header;
