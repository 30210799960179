// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.car__details .specification__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.car__details .specification__row:not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.car__details .specification__label {
    font-size: 1rem;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/styles/car-details.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;AACA;IACI,6BAA6B;IAC7B,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".car__details .specification__section {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n.car__details .specification__row:not(:last-child) {\n    border-bottom: 1px solid #ddd;\n    padding-bottom: 10px;\n    margin-bottom: 20px;\n}\n.car__details .specification__label {\n    font-size: 1rem;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
