import React from 'react'
import { Container, Row, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import "../../styles/footer.css"

const contactInfo = [
    {
        icon: '<i className="ri-home-7-fill"></i>',
        display: 'Boss Car Leasing Pte. Ltd.'
    },
    {
        icon: '<i className="ri-map-pin-fill"></i>',
        display: "155 Kaki Bukit Ave 1, Level 2 Shun Li Industrial Park, <br> Singapore 416012"
    },
    {
        icon: '<i className="ri-phone-line"></i>',
        display: '+65 6748 9747, +65 8876 8853'
    },
    {
        icon: '<i className="ri-mail-line"></i>',
        display: 'info@bosscarleasing.com '
    },
]

const quickLinks = [
    {
        path: '/',
        display: 'Home'
    },
    {
        path: '/aboutus',
        display: 'About Us'
    },
    {
        path: '/vehicles',
        display: 'Vehicle Listing'
    },
    {
        path: '/contactus',
        display: 'Contac Us'
    }
]

const Footer = () => {
    const date = new Date()
    const year = date.getFullYear()

    return <footer className='footer'>
            <Container>
                <Row>
                    <Col lg='4' md='4' sm='12'>
                        <h5 className='footer__link-title'>CONTACT INFO</h5>
                        <ListGroup>
                            {
                                contactInfo.map((item, index) => (
                                    <ListGroupItem key={index} className="p-0 mt-3 quick__link d-flex align-items-center gap-2">
                                      <span dangerouslySetInnerHTML={{ __html: item.icon }} /> 
                                      <span dangerouslySetInnerHTML={{ __html: item.display }} />
                                    </ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </Col>
                    <Col lg='3' md='3' sm='12'>
                        <div className='mb-4'>
                            <h5 className='footer__link-title'>Quick Links</h5>
                            <ListGroup>
                                {
                                    quickLinks.map((item, index) => (
                                        <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                                            <Link to={item.path}>{item.display}</Link>
                                        </ListGroupItem>
                                    ))
                                }
                            </ListGroup>
                        </div>
                    </Col>
                    <Col lg='3' md='3' sm='12'>
                        <h5 className='footer__link-title mb-4'>OPEN HOURS</h5>
                        <div className='mb-4'>
                            <h6 className='footer__link-sub-title mb-2'>Service Department</h6>
                            <p className='office__info mb-0'>Mon-Sat : 9:00am - 6:30pm</p>
                            <p className='office__info'>Closed on Sunday and Public Holidays</p>
                        </div>
                        <div className='mb-4'>
                            <h6 className='footer__link-sub-title mb-2'>Sales Department</h6>
                            <p className='office__info mb-0'>Mon-Sat : 9:00am - 6:30pm</p>
                            <p className='office__info'>Closed on Sunday and Public Holidays</p>
                        </div>
                    </Col>
                    <Col lg='2' md='2' sm='12'>
                        <h5 className='footer__link-title'>OTHERS</h5>
                        <ListGroup>
                            <ListGroupItem className="p-0 mt-3 quick__link">
                                <Link to="/privacy-policy"> Privacy </Link>
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                    <Col lg="12">
                        <div className='footer__bottom'>
                            <p className='section__description d-flex align-items-center justify-content-center gap-1 pt-4'>
                                <i className="ri-copyright-line"></i> Copyright {year}, Developed By MajuTech. All rights reserved.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
    </footer>
}

export default Footer