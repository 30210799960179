// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-date-picker {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
}

.custom-label {
    font-weight: bold;
    color: #000;
    width: 150px;
    display: inline-block;
}

.custom-form-group {
    margin-top: 20px;
    margin-bottom: 20px;
}

.react-datepicker-wrapper {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/styles/booking-form.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".custom-date-picker {\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    padding: 8px;\n}\n\n.custom-label {\n    font-weight: bold;\n    color: #000;\n    width: 150px;\n    display: inline-block;\n}\n\n.custom-form-group {\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.react-datepicker-wrapper {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
