import React from 'react'

const NotFound = () => {
    return <div style={{ 
        height: '300px', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.8rem',
        fontWeight: 'bold' }}
        >
        404 Page Not Found !
        </div>
}

export default NotFound