import { API_URL } from '../config/constants/common.js'
import {
    holidayListPending,
    holidayListSuccess,
    holidayListFailure,
} from '../reducers/holidaySlice'
import { memberAuthHeader, authHeader } from '../utils/authHeaders.js'

export const fetchHolidayList = () => async(dispatch) => {
    dispatch(holidayListPending())

    return fetch(`${API_URL}holidays` , {
        method: 'GET',
        headers: authHeader(),
    })
    .then(res => res.json())
    .then(result => {
        if (result.code == 200) {
            dispatch(holidayListSuccess(result.data))
            return Promise.resolve(true)
        } else if (result.code == 400) {
            dispatch(holidayListFailure('Bad Request'))
            return Promise.reject(result)
        } else {
            dispatch(holidayListFailure(result.error))
            throw (result.error)
        }
    })
    .catch(error => {
        dispatch(holidayListFailure(error.message))
        return Promise.reject(error)
    })

}